import React, { FC, useEffect, useRef } from 'react';
import { NotificationManager } from 'react-notifications';
import rawConfig, { AppConfig } from '../../../config/config';
import { HOME, INSURANCE_BUSINESS_TYPES, LOB_REFRESH_TIMEOUT, SELECT } from '../../../constants';
import { saveSelectedCarrier } from '../../../pages/redux/actions';
import { useAppDispatch, useAppSelector } from '../../../pages/redux/hooks';
import { orderLOBRates } from '../../../utils';
import {
  useExecuteQuoteRequestMutation,
  useHomeQuoteRequestPollQuery,
  useHomeQuoteRequestQuery,
  useOrganizationQuery,
} from '../../redux/apis';
import { setHomeQuoteDetails } from '../../redux/slices';
import { getLoaderImage, getSortedHomeQuoteList, isSFDCApplicationView } from '../../utils';
import HomeQuoteList from '../home-quotes';
import { DrawNoQuoteAvailable, IncompleteQuoteDrawer } from '../rate-details/draw-quote-detail';
import { HomeRateProps } from './types';
import { isEmpty } from 'lodash';
import { QuoteRequestStatus } from '../../../graphql/generated';

const config = rawConfig as AppConfig;
const QuoteLoader = () => (
  <div className='w-100 mt-4 d-flex justify-content-center'>
    <img src={getLoaderImage(INSURANCE_BUSINESS_TYPES.HOME)} alt='loader' />
  </div>
);

const IncompleteQuoteText = () => (
  <>
    <h1>Incomplete Quote</h1>
    <p className='clickhere-text'>Please Click Here To Finish</p>
  </>
);

export const HomeRates: FC<HomeRateProps> = ({ redirectToCertainTab, updateSelectedQuoteList }) => {
  const {
    common: { isVeriskLoading, selectedCarrierLoader },
    home: { herd_id, productType },
    homeQuoteDetails: {
      continuePolling,
      isNoQuotesAvailable,
      isPolling,
      isRefetchHomePollQuote,
      isViewingQuote,
      pendingQuotes,
      quotes,
      quoteRequestStatus,
    },
  } = useAppSelector(state => state);
  const mountRef = useRef(false);
  const dispatch = useAppDispatch();
  const { data: { organization } = {} } = useOrganizationQuery();
  const [executeHomeQuote, { isLoading: isExecuteQuoteLoading, isSuccess: isSuccessExecuteQuote }] =
    useExecuteQuoteRequestMutation();

  const { isLoading: isHomeQuoteRequestLoading } = useHomeQuoteRequestQuery(
    {
      id: herd_id,
    },
    {
      skip: isEmpty(herd_id) || !isViewingQuote,
    }
  );
  const isStopPolling = !isRefetchHomePollQuote && !continuePolling;
  const isLoadingHomeQuote = isRefetchHomePollQuote ? true : isPolling ?? isExecuteQuoteLoading;
  const { isError: isHomePollQuoteRequestErr = null } = useHomeQuoteRequestPollQuery(
    { id: herd_id },
    {
      pollingInterval: 1000,
      skip:
        isStopPolling ||
        !isLoadingHomeQuote ||
        isEmpty(herd_id) ||
        quoteRequestStatus === QuoteRequestStatus.Created,
    }
  );

  if (!!isHomePollQuoteRequestErr) {
    dispatch(setHomeQuoteDetails({ isPolling: false, continuePolling: false }));
  }
  const successQuotes = quotes.filter(quote => !!quote?.data);
  const errorQuotes = quotes.filter(quote => !!quote?.error);
  let quotesList = getSortedHomeQuoteList(
    quotes,
    pendingQuotes,
    organization?.displayErrorsOnUi,
    isRefetchHomePollQuote ? false : isPolling
  );
  const initialLoaderRef = useRef(true);

  useEffect(() => {
    if (mountRef.current === false) {
      mountRef.current = true;
    }
  }, []);

  const callExecuteQuote =
    !isSuccessExecuteQuote &&
    ((!isViewingQuote && !isSFDCApplicationView()) || (isSFDCApplicationView() && isViewingQuote));
    
  useEffect(() => {
    if (isLoadingHomeQuote) {
      dispatch(setHomeQuoteDetails({ isNoQuotesAvailable: false }));
    } else if (!isEmpty(herd_id) && quoteRequestStatus === QuoteRequestStatus.Complete) {
      dispatch(
        setHomeQuoteDetails({
          isNoQuotesAvailable: mountRef.current && !callExecuteQuote && !quotes.length,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHomeQuote, quotes.length, quoteRequestStatus]);

  useEffect(() => {
    if (
      !!initialLoaderRef?.current &&
      (quotes?.length ||
        isExecuteQuoteLoading ||
        isPolling ||
        isEmpty(herd_id) ||
        (quoteRequestStatus === QuoteRequestStatus.Complete && quotes?.length === 0))
    ) {
      initialLoaderRef.current = false;
    }
  }, [herd_id, quotes, isExecuteQuoteLoading, isPolling, quoteRequestStatus]);

  const fetchHomeQuotes = async () => {
    try {
      if (!isViewingQuote && !isSuccessExecuteQuote && !isExecuteQuoteLoading) {
        await executeHomeQuote({ id: herd_id }).unwrap();
      }
      dispatch(setHomeQuoteDetails({ isPolling: true, continuePolling: true }));
    } catch (error) {
      NotificationManager.error('Something went wrong: ' + (error as Error).message);
    }
  };

  const selectDefaultQuote = () => {
    const defaultQuote = quotesList[0];
    const selectedQuoteList = JSON.parse(sessionStorage.selectedQuoteList || '[]');
    if (selectedQuoteList && Array.isArray(selectedQuoteList)) {
      const isPresent = selectedQuoteList.find(quote => {
        return quote?.type === HOME && quote?.price === defaultQuote?.data?.price;
      });

      if (!isPresent) {
        const tmpList = [...selectedQuoteList, defaultQuote];
        const orderedList = orderLOBRates(tmpList);
        sessionStorage.selectedQuoteList = JSON.stringify(orderedList);
        if (!selectedCarrierLoader && herd_id) {
          dispatch(
            saveSelectedCarrier({
              action: SELECT,
              lobType: HOME,
              selectedQuote: defaultQuote?.data,
              herdId: herd_id,
              carrierId: defaultQuote?.carrierId,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (herd_id && !quotes.length && !isNoQuotesAvailable) {
      fetchHomeQuotes();
      const pollingTimer = setTimeout(
        () => {
          dispatch(setHomeQuoteDetails({ isPolling: false }));
        },
        // poll only once if view quote
        isViewingQuote ? 100 : Number(config.pollingTimerTimeout)
      );
      return () => {
        dispatch(setHomeQuoteDetails({ isPolling: false }));
        clearTimeout(pollingTimer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [herd_id, continuePolling]);

  useEffect(() => {
    if (isRefetchHomePollQuote) {
      dispatch(setHomeQuoteDetails({ isPolling: true, continuePolling: true }));
      const pollingTimer = setTimeout(() => {
        dispatch(setHomeQuoteDetails({ isRefetchHomePollQuote: false }));
      }, LOB_REFRESH_TIMEOUT);
      return () => {
        dispatch(setHomeQuoteDetails({ isPolling: false }));
        clearTimeout(pollingTimer);
      };
    }
    // eslint-disable-next-line
  }, [isRefetchHomePollQuote]);
  
  useEffect(() => {
    if (!isPolling && quotes.length) {
      selectDefaultQuote();
    }
    // eslint-disable-next-line
  }, [quotes.length, isPolling]);

  const showHomeQuoteLoader =
    !!isPolling ||
    !!initialLoaderRef?.current ||
    !!isExecuteQuoteLoading ||
    !!isHomeQuoteRequestLoading || 
    !!(!quotesList?.length && isRefetchHomePollQuote);

  if (
    quoteRequestStatus !== QuoteRequestStatus.Created &&
    (quotesList.length || showHomeQuoteLoader)
  ) {
    return (
      <>
        {quotesList?.map((quote, index: number) => (
          <HomeQuoteList
            key={quote.carrierId}
            quoteList={quotesList}
            position={index}
            quote={quote}
            updateSelectedQuoteList={updateSelectedQuoteList}
            errorQuotes={errorQuotes}
            successQuotes={successQuotes}
            selectedQuoteList={
              sessionStorage.selectedQuoteList ? JSON.parse(sessionStorage.selectedQuoteList) : []
            }
          />
        ))}
        {!!(isLoadingHomeQuote || showHomeQuoteLoader) && <QuoteLoader />}
      </>
    );
  }

  if (isNoQuotesAvailable && !isLoadingHomeQuote && !showHomeQuoteLoader) {
    return <DrawNoQuoteAvailable type={HOME} />;
  }

  return (
    <IncompleteQuoteDrawer
      currentQuoteTab={HOME}
      incompleteQuoteText={IncompleteQuoteText}
      redirectToCertainTab={redirectToCertainTab}
      productType={productType}
      isVeriskLoading={isVeriskLoading}
    />
  );
};
