import { get, isEmpty } from 'lodash';
import { setFloodInfoRequired, setPrefillData } from '../../pages/redux/actions';
import store from '../../pages/redux/store';
import QuestionList from '../../constants/flood-questionnaire';
import { ENCLOSURE_SQ_FT, FOUNDATION_TYPES, HO3, SLAB, STORIES, YES } from '../../constants';
import { api, FoundationType, Stories } from '../../graphql/generated';
import {
  getFloodQuestionnairDetailsFromPrefill,
  getFoundationTypeMapper,
  getStoriesMapper,
} from './property-info';
import { LineOfBusiness } from '../enums';
import { setCoverageDetails } from '../redux/slices';

export const createFloodQuestionnaire = (type?: FoundationType) => {
  const {
    applicant: {
      personal_details: { product = '' },
    },
    floodDetails,
    propertyInfoDetails: { propertyInfo }
  } = store.getState();
  const annexData: any = floodDetails?.annexPrefill || {};
  const floodQuestionnaireAdditionalData: any = annexData?.floodQuestionnaireAdditionalData ?? {};
  const { foundationType, noOfStories } = getFloodQuestionnairDetailsFromPrefill();
  const stories =
    noOfStories ?? getStoriesMapper[propertyInfo?.building?.general?.stories ?? Stories.One];
  const propertyType = product || HO3?.toLowerCase();
  const isFloodInfo = !!floodDetails?.isFloodInfoRequired;
  const foundationTypeDatails = (type ||
    propertyInfo.building?.foundation?.type ||
    annexData.structureType ||
    FoundationType.ConcreteSlab) as FoundationType;
  const mappedDetails = {
    foundationType: foundationTypeDatails
      ? getFoundationTypeMapper[foundationTypeDatails]
      : foundationType,
    stories,
    propertyType,
    foundationOptions: [...FOUNDATION_TYPES],
  };
  let currentQuestion: any = {};
  // Determine current question based on conditions
  if (!isEmpty(mappedDetails.foundationType)) {
    const selectedfoundationType =
      mappedDetails.foundationType.toLowerCase() === SLAB && isFloodInfo
        ? STORIES
        : mappedDetails.foundationType;
    const question = get(QuestionList, selectedfoundationType, QuestionList.foundationType);
    const currentOptions = isEmpty(selectedfoundationType)
      ? mappedDetails.foundationOptions
      : get(question, 'options', []);
    currentQuestion = {
      ...question,
      options: [...currentOptions],
    };
    if (selectedfoundationType?.toLowerCase() === SLAB) {
      currentQuestion.value = mappedDetails.stories;
      currentQuestion.submit = true;
    }
  } else {
    currentQuestion = get(QuestionList, STORIES, {});
  }
  if (!isEmpty(floodQuestionnaireAdditionalData)) {
    if (foundationType === STORIES) {
      currentQuestion.value = floodQuestionnaireAdditionalData.stories || stories || 1;
    }
    if (currentQuestion?.linkQuestion?.name === ENCLOSURE_SQ_FT) {
      currentQuestion.linkQuestion.value = floodQuestionnaireAdditionalData.enclosureSqft;
    }
    currentQuestion.value = floodQuestionnaireAdditionalData.additionalStructureType || '';
  }
  store.dispatch(
    setPrefillData({
      ...mappedDetails,
      currentQuestion,
    })
  );
};

export const getIsFloodInfoRequired = (foundationTypeData?: FoundationType): boolean => {
  const {
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const { Basement, Crawlspace, PierOrPilings } = FoundationType;
  const floodQuestionnaireList = [Basement, Crawlspace, PierOrPilings];
  if (foundationTypeData && floodQuestionnaireList.includes(foundationTypeData)) {
    return true;
  }
  const foundationType = propertyInfo?.building?.foundation?.type;
  if (!foundationType) {
    return false;
  }
  return floodQuestionnaireList.includes(foundationType);
};

export const getAnnexDetails = (lob?: string) => {
  const {
    applicant,
    floodDetails,
    home: { address, personal_details, productType },
    propertyInfoDetails: { coverageDetails, deductibleDetails, propertyInfo, propertyOtherDetails },
  } = store.getState();
  const { data: { organization } = {} } = api.endpoints.organization.select()(store.getState());
  const { building, rebuildingCost, site } = propertyInfo || {};
  const personalDetails = applicant?.personal_details ?? personal_details ?? {};
  const addressDetails: any = applicant?.address ?? address ?? {};
  const firstName = personalDetails?.first_name ?? '';
  const lastName = personalDetails?.last_name ?? '';
  const middleName = personalDetails?.middle_name ?? '';
  const applicantAddress = {
    addressLine1: addressDetails?.addressLine1 ?? '',
    addressLine2: addressDetails?.addressLine2 ?? '',
    city: addressDetails?.city ?? '',
    country: addressDetails?.country ?? '',
    county: addressDetails?.county ?? '',
    state: addressDetails?.state ?? '',
    street: addressDetails?.street ?? '',
    unit: addressDetails?.unit ?? '',
    validated: !!addressDetails?.validated,
    zip: addressDetails?.zip ?? '',
  };
  const deductible = deductibleDetails?.base ?? personalDetails?.preferredDeductible ?? 1000;
  return {
    authToken: organization?.authToken ?? '',
    product: productType ?? '',
    personalInformation: {
      product: productType ?? '',
      dob: personalDetails?.date_of_birth ?? '',
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      email: personalDetails?.email ?? '',
      phone: personalDetails?.phone_number ?? '',
      address: applicantAddress,
      industry: personalDetails?.industry ?? '',
      is_condo: !!productType && productType?.toUpperCase() === HO3,
      education: personalDetails?.education ?? '',
      occupation: personalDetails?.occupation ?? '',
    },
    stories: getStoriesMapper[building?.general?.stories ?? Stories.One],
    yearBuilt: building?.general?.yearBuilt ?? undefined,
    occupancyType: productType ?? '',
    deductible: deductible,
    covA: coverageDetails?.coverageA,
    covB: coverageDetails?.coverageB,
    covC: coverageDetails?.coverageC,
    covD: coverageDetails?.coverageD,
    covE: coverageDetails?.coverageE,
    covF: coverageDetails?.coverageF,
    waterBackup: coverageDetails?.waterBackup,
    swimmingPool: !!site?.swimmingPool?.isFenced,
    squareFootage: building?.general?.squareFootage ?? undefined,
    structureType: building?.foundation?.type ?? '',
    rebuildingCost: rebuildingCost?.hippo?.medium,
    address: applicantAddress,
    lob: lob ?? LineOfBusiness.FLOOD,
    insured: {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
    },
    isFloodInfo: floodDetails?.isFloodInfoRequired,
    residenceType: propertyOtherDetails?.residenceType?.toUpperCase(),
    personalPropertyReplacementCost: YES,
    previousPolicyCancelled: propertyOtherDetails?.previousPolicyCancelled,
  };
};

export const updateCoverageDetailsForFlood = (annexDetails: any) => {
  if (annexDetails) {
    store.dispatch(
      setCoverageDetails({
        coverageA: annexDetails?.covA ?? 0,
        coverageB: annexDetails?.covB ?? 0,
        coverageC: annexDetails?.covC ?? 0,
        coverageD: annexDetails?.covD ?? 0,
        coverageE: annexDetails?.covE ?? 0,
        coverageF: annexDetails?.covF ?? 0,
        coverageL: annexDetails?.covL ?? 0,
        waterBackup: annexDetails?.waterBackup ?? 1000,
      })
    );
  }
};

export const getFoundationType = (structureType: string = '') => {
  const lowerStructureType = structureType.toLowerCase();
  for (const foundationKey in getFoundationTypeMapper) {
    if (lowerStructureType.includes(getFoundationTypeMapper[foundationKey as FoundationType])) {
      return FoundationType[foundationKey as FoundationType];
    }
  }
  return '';
};

export const updateFloodQuestionnaireDetails = (structureType: string = '') => {
  const foundationType = getFoundationType(structureType);
  if (!isEmpty(foundationType)) {
    createFloodQuestionnaire(foundationType as FoundationType);
    store.dispatch(setFloodInfoRequired(getIsFloodInfoRequired(foundationType as FoundationType)));
  }
};
