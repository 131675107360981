/* istanbul ignore file */
import { AxiosError } from 'axios';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import moment from 'moment';
import { v4 } from 'uuid';
import { filter, find, findIndex, forEach, get, has, isEmpty, omit } from 'lodash';
import axiosRequest from '../../api';
import {
  checkAnnexPrefill,
  dateFormat,
  getSeperateQuoteList,
  clearLobQuotes,
  createPageTracking,
  getLobHerdId,
  simplifyPhoneNumber,
  setAnnexInitialQuestion,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
  mappedResponseToStoreForPropertyDetails,
  getIsCondoProduct,
  getPreferredDeductible,
  getResidencyStatus,
} from '../../../utils';
import { showFields, updateApostrophe } from '../../home/utils';
import {
  FOUNDATION_TYPES,
  INSURANCE_PRODUCTS,
  OTHER_PRODUCTS,
  SUBMIT_VALUES,
  LOB_REFRESH_TIMEOUT,
} from '../../../constants';
import * as actions from '../constants';
import QuestionList from '../../../constants/flood-questionnaire';
import {
  autoSubmitAnnex,
  errorHandler,
  fetchAnnexQuotes,
  floodBridgeToCarrierLoader,
  floodTimeoutPolling,
  initializeFloodPolling,
  questionnaireSubmitLoader,
  setFloodDetailsLoader,
  setFloodRequestSuccess,
  setHomeState,
  setPrefillData,
  showFloodDetailsModal,
  setNextTabStep,
  setFloodDetails,
  setLobRefreshLoader,
  setFloodQuotes,
  setLineOfBusiness,
  setFloodHerdId,
  setAnnexPrefill,
  storeErrorsForBridgeToCarrier,
  setFloodPollingDetails,
  stopFloodPolling,
  setFloodPollingErrorDetails,
  setApplicantDetails,
  setFloodInfoRequired,
} from '../actions';
import { PrefillDetails } from '../reducers/applicant';
import config from '../../../config/config';
import {
  createFloodQuestionnaire,
  getAnnexDetails,
  getIsFloodInfoRequired,
  isEnabledPRSv2Flow,
} from '../../../quote-application/utils';
import { FoundationType } from '../../../graphql/generated';
import { LineOfBusiness } from '../../../quote-application/enums';

const floodDetailsAction = actions;
const pollConfig = {
  publicUrl: config.serverURL,
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};
const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

const getUpdatedAnswers = (list: Array<any>, question: any) => {
  let currentList = [...list];
  const currentIndex = findIndex(list, { name: question.name });
  if (currentIndex !== -1) {
    currentList[currentIndex] = { ...question };
  } else {
    currentList = [...currentList, question];
  }
  return currentList;
};

const getCurrentQuestion = (answers: Array<any>, question: any) => {
  const answeredQuestion = find(answers, { name: question.name });
  if (answeredQuestion && answers.length !== 0) {
    return Object.assign(question, answeredQuestion);
  } else {
    return question;
  }
};

function* getFloodQuotes(details: any) {
  try {
    yield put(fetchAnnexQuotes(details));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

function* questionnaireSubmitDetails({ payload }: any) {
  const currentQuestion = { ...payload };
  const {
    floodDetails: {
      stories,
      currentIndex,
      currentQuestion: floodStepQuestions,
      answeredQuestions,
      foundationType,
      foundationOptions,
    },
    common: { lineOfBusiness },
  } = yield select();
  if (lineOfBusiness !== OTHER_PRODUCTS[0].value) {
    yield put(setLineOfBusiness(OTHER_PRODUCTS[0].value));
  }
  let floodQuestionnaireAdditionalData: any = null;
  if (
    !!floodStepQuestions?.title &&
    !!currentQuestion?.title &&
    floodStepQuestions?.title?.trim() === currentQuestion?.title?.trim()
  ) {
    floodQuestionnaireAdditionalData = {
      additionalStructureType:
        !!currentQuestion?.value && foundationType !== FOUNDATION_TYPES[5].value
          ? currentQuestion?.value
          : '',
      enclosureSqft: !!currentQuestion?.linkQuestion?.value
        ? currentQuestion?.linkQuestion?.value
        : '',
      stories:
        foundationType === FOUNDATION_TYPES[5].value
          ? currentQuestion?.value
          : stories
          ? stories
          : '',
    };
    let floodQuesData: any = { ...currentQuestion };
    yield put(setAnnexPrefill({ floodQuestionnaireAdditionalData }));
    yield put(setPrefillData({ currentQuestion: { ...floodQuesData } }));
  }

  let updatedDetails: any = null;
  const answers = getUpdatedAnswers(answeredQuestions, currentQuestion);
  if (
    currentQuestion?.checkFoundationType &&
    !currentQuestion.foundation &&
    isEmpty(foundationType)
  ) {
    updatedDetails = {
      answeredQuestions: [...answers],
      currentQuestion: {
        ...getCurrentQuestion(answers, get(QuestionList, 'foundationType')),
        options: [...foundationOptions],
      },
    };
  } else {
    const foundationValue = currentQuestion.foundation ? currentQuestion.value : foundationType;
    let updatedAnswers = [...answers];
    if (currentQuestion.foundation) {
      updatedAnswers = filter(updatedAnswers, (ansDetails: any) => !has(ansDetails, 'submit'));
    }

    updatedDetails = {
      answeredQuestions: [...updatedAnswers],
      currentQuestion: get(QuestionList, foundationValue),
    };

    if (currentQuestion?.submit || SUBMIT_VALUES.includes(foundationValue)) {
      const finalDetails: any = {};
      let isFloodQuestionnaire: boolean = false;
      forEach(updatedDetails.answeredQuestions, (answer: any) => {
        if (!isEmpty(Object.keys(answer))) isFloodQuestionnaire = true;
        if (answer.name !== 'foundationType') {
          finalDetails[answer.name] = answer.value;
          if (has(answer, 'linkValues') && answer.linkValues.includes(answer.value)) {
            finalDetails[answer.linkQuestion.name] = answer.linkQuestion.value;
          }
        } else if (SUBMIT_VALUES.includes(answer.value)) {
          finalDetails.structureType = answer.value;
        }
      });
      if (isFloodQuestionnaire) {
        yield put(setNextTabStep(true));
      }
      finalDetails.floodQuestionnaireAdditionalData = floodQuestionnaireAdditionalData;
      yield call(getFloodQuotes, finalDetails);
    }
  }
  const { floodDetails } = yield select();
  if (payload?.name === 'stories') {
    updatedDetails.stories = payload?.value;
  }
  if (
    !currentQuestion?.submit &&
    !SUBMIT_VALUES.includes(currentQuestion.value) &&
    !checkAnnexPrefill(currentQuestion.value, floodDetails.foundationType)
  ) {
    yield put(setPrefillData({ ...updatedDetails, currentIndex: currentIndex + 1 }));
  }
}

export function* questionnaireSubmitWatcher() {
  yield takeEvery(floodDetailsAction.QUESTIONNAIRE_SUBMIT, questionnaireSubmitDetails);
}

function* questionnaireBackDetails() {
  const {
    floodDetails: { currentIndex, answeredQuestions },
  } = yield select();
  const previousQuestionIndex = currentIndex - 1;
  yield put(
    setPrefillData({
      currentQuestion: { ...answeredQuestions[previousQuestionIndex] },
      currentIndex: previousQuestionIndex,
    })
  );
}

export function* questionnaireBackWatcher() {
  yield takeEvery(floodDetailsAction.QUESTIONNAIRE_BACK, questionnaireBackDetails);
}

function* handleFloodError() {
  const {
    common: { lineOfBusiness },
  } = yield select();
  if (lineOfBusiness !== INSURANCE_PRODUCTS[0]) {
    yield put(setFloodQuotes({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] }));
    yield put(
      setHomeState({
        hippoQuoteLoaded: true,
        showError: false,
      } as any)
    );
  } else {
    yield put(showFloodDetailsModal(false));
  }
}

function* initializeQuotesPolling() {
  const { floodDetails: quotesDetails } = yield select();
  if (quotesDetails?.isQuoteRefresh) {
    yield put(
      setFloodDetails({
        isQuoteRefresh: false,
      } as any)
    );
  }
  const quoteLimit = get(quotesDetails, 'quotingDetails.totalQuotesToPoll', 0);
  if (quotesDetails.quoteErrorList.length < quoteLimit) {
    yield put(initializeFloodPolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(floodTimeoutPolling());
  }
}

function* fetchAnnexQuotesDetails({ payload }: any): any {
  const {
    applicant,
    common: { lineOfBusiness },
    floodDetails: { annexPrefill, herdId },
    partner: { appointments, isProviderOneLogin, storedRequestId },
    common,
    home,
    propertyInfoDetails: { propertyInfo }
  } = yield select();
  const { is_condo } = home;
  try {
    yield put(setLobRefreshLoader(false));
    trackFirstLoadQuoteInHeap();
    yield put(questionnaireSubmitLoader(true));
    yield put(
      setFloodDetails({
        quoteStatus: '',
      })
    );
    const annexDetails = { ...annexPrefill, ...payload };
    const keys = Object.keys(appointments);
    annexDetails.selected_lob = keys;
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } =
      clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    annexDetails.authToken = annexDetails.authToken || daleConfig?.auth_token;
    annexDetails.lobQuotesToClear = lobQuotesToClear;
    annexDetails.clearAutoInfo = clearAutoInfo;
    annexDetails.clearPetInfo = clearPetInfo;
    annexDetails.clearLifeInfo = clearLifeInfo;
    annexDetails.clearUmbrellaInfo = clearUmbrellaInfo;
    annexDetails.pageTracking = pageTracking;
    annexDetails.organizationId = organizationId;
    annexDetails.lob = INSURANCE_PRODUCTS[2];
    annexDetails.herdId = herdId || '';
    applicant.personal_details.phone_number = applicant?.personal_details?.phone_number
      ? simplifyPhoneNumber(applicant?.personal_details?.phone_number)
      : '';
    annexDetails.personalInformation = {
      ...omit(applicant?.personal_details, [
        'preferredDeductible',
        'date_of_birth',
        'phone_number',
      ]),
      dob: applicant?.personal_details?.date_of_birth || '',
      phone: applicant?.personal_details?.phone_number || '',
      address: !isEmpty(applicant?.address) ? { ...applicant?.address } : annexDetails?.address,
      product: annexDetails?.product || applicant?.product || '',
      is_condo: getIsCondoProduct(is_condo) || annexDetails?.is_condo,
    };
    if (isEnabledPRSv2Flow() && propertyInfo?.propertyId) {
      annexDetails.propertyId = propertyInfo?.propertyId;
      annexDetails.revision = propertyInfo?.revision;
    } else {
      annexDetails.residenceType = getResidencyStatus();
      annexDetails.deductible = getPreferredDeductible() || '';
    }
    const updatedAnnexDetails = {
      ...annexDetails,
      address: {
        ...annexDetails.address,
        state: (annexDetails?.address?.state?.toUpperCase() || '')
      }
    };
    const details = yield axiosRequest('POST', '/annex/get-flood-quotes', isProviderOneLogin, {
      ...updatedAnnexDetails,
      provider_request_id: storedRequestId,
    });
    const { floodDetails } = yield select();
    const showFloodQuotes =
      lineOfBusiness === INSURANCE_PRODUCTS[0] ? floodDetails.showFloodDetailsModal : true;
    if (showFloodQuotes) {
      if (details?.data) {
        const { home } = yield select();
        const { upcomingCarrierList, errorDetails, quotingCarriers, totalQuotesToPoll } =
          details.data;
        const { errorQuotes } = getSeperateQuoteList(errorDetails, home);
        yield put(
          setFloodDetails({
            quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
            quotingDetails: details.data,
            isPollingStart: totalQuotesToPoll > 0 ? true : false,
            upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
            quoteStatus: '',
          })
        );
        if (errorQuotes.length) {
          yield put(setFloodQuotes({ quoteErrorList: errorQuotes }));
        }
        yield put(setFloodRequestSuccess(true));
        trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[2]);
        yield call(initializeQuotesPolling);
      } else {
        yield call(handleFloodError);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data?.errorDetails) {
      const { errorDetails } = axiosError?.response?.data;
      const { errorQuotes } = getSeperateQuoteList(errorDetails, {});
      yield put(setFloodQuotes({ quoteErrorList: [...errorQuotes] }));
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[2]);
    } else {
      yield call(handleFloodError);
    }
    yield put(setNextTabStep(false));
  }
  yield put(setFloodDetailsLoader(false));
  yield put(questionnaireSubmitLoader(false));
}

export function* fetchAnnexQuotesWatcher() {
  yield takeEvery(floodDetailsAction.FETCH_ANNEX_QUOTES, fetchAnnexQuotesDetails);
}

function* autoSubmitAnnexDetails() {
  const {
    floodDetails: { annexPrefill },
  } = yield select();
  const stories = annexPrefill?.stories || null;
  const foundationType = annexPrefill?.structureType || '';
  if (checkAnnexPrefill(stories, foundationType)) {
    yield put(fetchAnnexQuotes(annexPrefill));
  } else {
    yield put(setFloodDetailsLoader(false));
  }
}

export function* autoSubmitAnnexWatcher() {
  yield takeEvery(floodDetailsAction.AUTO_SUBMIT_ANNEX, autoSubmitAnnexDetails);
}

function* switchFloodModalDetails() {
  const {
    floodDetails: { annexPrefill },
  } = yield select();
  const stories = annexPrefill?.stories || null;
  const foundationType = annexPrefill?.structureType || '';
  if (checkAnnexPrefill(stories, foundationType)) {
    yield put(setFloodDetailsLoader(true));
    yield put(autoSubmitAnnex());
  }
}

export function* switchFloodModalWatcher() {
  yield takeEvery(floodDetailsAction.SWITCH_FLOOD_MODAL, switchFloodModalDetails);
}

function* trackAnnexData({ payload }: any): any {
  try {
    const {
      home: { herd_id },
      partner: { isProviderOneLogin },
    } = yield select();
    yield axiosRequest('POST', '/annex/update-analytics', isProviderOneLogin, {
      ...payload,
      herd_id,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* trackAnnexDetailsWatcher() {
  yield takeEvery(floodDetailsAction.TRACK_ANNEX_DETAILS, trackAnnexData);
}

function* formFieldsVisibility() {
  const {
    home: { pconfig },
  } = yield select();
  return {
    phone_number: showFields('phone_number', false),
    email: showFields('email', pconfig.dale_config.form_parameters_visibility),
  };
}

export function* getQuoteDetails(): any {
  const reduxStore = yield select();
  const {
    home: { code, address, pconfig, is_condo, personal_details, productType },
    common: { pageTracking },
    auth,
    partner: { storedRequestId, producerDetails, appointments },
    applicant: { address: applicantAddress },
    floodDetails,
  } = reduxStore;
  const showValidFields = yield call(formFieldsVisibility);
  const quoteBy = !isEmpty(get(auth, 'email', ''))
    ? get(auth, 'email')
    : producerDetails && !isEmpty(producerDetails.producerEmail)
    ? producerDetails.producerEmail
    : '';
  const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } =
    clearLobQuotes();
  let pageTrackings = createPageTracking(!clearAutoInfo ? pageTracking : {}, appointments);
  return {
    pageTracking: pageTrackings,
    herd_id: floodDetails?.herdId || '',
    lobQuotesToClear,
    clearAutoInfo,
    clearPetInfo,
    clearLifeInfo,
    clearUmbrellaInfo,
    lob: INSURANCE_PRODUCTS[2],
    address: address ? address : applicantAddress,
    is_condo: getIsCondoProduct(is_condo),
    home_type: productType,
    quote_by: quoteBy,
    id: get(auth, 'userId', ''),
    first_name: updateApostrophe(personal_details.first_name),
    middle_name: updateApostrophe(personal_details.middle_name),
    last_name: updateApostrophe(personal_details.last_name),
    dob: moment(personal_details.date_of_birth, dateFormat).format(dateFormat),
    phone_number: showValidFields.phone_number
      ? simplifyPhoneNumber(personal_details.phone_number)
      : '',
    email: showValidFields.email ? personal_details.email : '',
    partner_code: code ? code : 'naaisg',
    request_id: v4(),
    auth_token: pconfig?.dale_config?.auth_token,
    organizationId: pconfig?.dale_config?.id,
    industry: personal_details.industry,
    occupation: personal_details.occupation,
    education: personal_details.education,
    preferredDeductible: getPreferredDeductible(),
    residency_status: getResidencyStatus(),
    provider_request_id: storedRequestId,
  };
}

function* fetchFloodQuotes(): any {
  yield put(questionnaireSubmitLoader(true));
  yield put(setFloodDetailsLoader(true));
  try {
    const reduxStore = yield select();
    const {
      partner: { isProviderOneLogin, appointments },
    } = reduxStore;
    yield put(
      setFloodDetails({
        quoteStatus: '',
      })
    );
    const keys = Object.keys(appointments);
    const quoteDetails = yield call(getQuoteDetails);
    quoteDetails.selected_lob = keys;
    const updatedQuoteDetails = {
      ...quoteDetails,
      address: {
        ...quoteDetails.address,
        state: (quoteDetails?.address?.state?.toUpperCase() || '')
      }
    };
    const details = yield axiosRequest('POST', '/partners/getquotes', isProviderOneLogin, {
      ...updatedQuoteDetails,
    });
    if (details.data) {
      const { annexRequiredPrefill, herdId } = details.data;
      yield put(setFloodHerdId(herdId || details?.data?.herd_id));
      const { prefillData, isPropertyInfoRequired, prefillStatus } = details?.data;
      yield put(
        setFloodDetails({
          isPropertyInfoRequiredForFlood: !!isPropertyInfoRequired,
        })
      );
      yield put(
        setApplicantDetails({
          prefillDetails: !isEmpty(prefillData)
            ? (mappedResponseToStoreForPropertyDetails(prefillData) as PrefillDetails)
            : {
                propertyData: {},
                label: {},
              },
          isPropertyInfoRequired: !!isPropertyInfoRequired,
          prefillStatus: prefillStatus || null,
          noOfRetries: 0,
        })
      );
      if (isEnabledPRSv2Flow()) {
        const annexData = getAnnexDetails();
        const structureType = annexData?.structureType as FoundationType || FoundationType.ConcreteSlab;
        yield put(setAnnexPrefill(annexData || {}));
        createFloodQuestionnaire(structureType);
        yield put(setFloodInfoRequired(getIsFloodInfoRequired(structureType as FoundationType)))
      }
      if (!isEmpty(annexRequiredPrefill) && !isEnabledPRSv2Flow()) {
        setAnnexInitialQuestion(details.data);
        yield put(setAnnexPrefill(annexRequiredPrefill || {}));
      }
      if (isEmpty(annexRequiredPrefill) && !isEnabledPRSv2Flow()) {
        if (keys.includes('FLOOD')) {
          yield put(questionnaireSubmitLoader(false));
          yield put(setFloodQuotes({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] }));
        }
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data?.error_details) {
      const { error_details, herdId } = axiosError?.response?.data;
      yield put(setFloodHerdId(herdId));
      const { errorQuotes } = getSeperateQuoteList(error_details, {});
      yield put(setFloodQuotes({ quoteErrorList: [...errorQuotes] }));
    } else {
      yield call(handleFloodError);
    }
  }
  yield put(questionnaireSubmitLoader(false));
  yield put(setFloodDetailsLoader(false));
}

export function* fetchFloodQuotesDetailsWatcher() {
  yield takeLatest(floodDetailsAction.FETCH_FLOOD_QUOTE_DETAILS, fetchFloodQuotes);
}

function* getBridgeToCarrierDetailsForFlood({ payload }: any): any {
  let loaderData = {
    loader: true,
    carrier_id: payload?.carrierId,
  };
  try {
    yield put(floodBridgeToCarrierLoader(loaderData));
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/quotes/bridge-carrier',
      isProviderOneLogin,
      {
        lob: payload?.lob ?? '',
        quoteId: payload?.quoteId ?? '',
        carrierId: payload?.carrierId ?? '',
        herdId: getLobHerdId(INSURANCE_PRODUCTS[2]) ?? '',
      }
    );
    if (!!responseDetails?.data) {
      if (!!responseDetails?.data?.paymentLink) {
        window.open(responseDetails?.data?.paymentLink, '_blank');
      } else {
        yield put(storeErrorsForBridgeToCarrier(responseDetails?.data));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (axiosError?.response?.status === 404) {
      yield put(storeErrorsForBridgeToCarrier(axiosError?.response?.data));
    }
    yield put(errorHandler(axiosError));
  }
  loaderData.loader = false;
  yield put(floodBridgeToCarrierLoader(loaderData));
}

export function* getBridgeToCarrierDetailsForFloodWatcher() {
  yield takeEvery(
    floodDetailsAction.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_FLOOD,
    getBridgeToCarrierDetailsForFlood
  );
}

function* pollQuotes(): any {
  while (true) {
    const {
      floodDetails,
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      const floodHerdId =
        getLobHerdId(LineOfBusiness.FLOOD) || floodDetails?.quotingDetails?.herdId;
      if (!(floodHerdId && floodDetails.isPollingStart)) return;
      const { data } = yield axiosRequest('POST', '/quotes/poll-quotes', isProviderOneLogin, {
        herd_id: floodHerdId,
        lob: LineOfBusiness.FLOOD,
      });
      if (!isEmpty(data.quotes)) {
        yield put(setFloodPollingDetails({ ...data }));
      }
      if (get(data, displayErrorProperty, false) && !isEmpty(get(data, errorProperty, {}))) {
        yield put(setFloodPollingErrorDetails({ ...data }));
      }
      const reduxState = yield select();
      const currentQuotesLength =
        reduxState.floodDetails.quoteList.length + reduxState.floodDetails.quoteErrorList.length;
      const quoteLimit = get(floodDetails, 'quotingDetails.totalQuotesToPoll', 0);
      if (currentQuotesLength >= quoteLimit) {
        yield put(questionnaireSubmitLoader(false));
        yield put(setFloodDetailsLoader(false));
        yield put(stopFloodPolling());
      } else {
        yield call(pollingDelay, pollConfig.pollingInterval);
      }
    } catch (error) {
      yield put(questionnaireSubmitLoader(false));
      yield put(setFloodDetailsLoader(false));
      yield put(stopFloodPolling());
      yield put(errorHandler(error));
    }
  }
}

function* pollQuotesWatcher() {
  while (true) {
    yield take(floodDetailsAction.INIT_FLOOD_POLLING);
    yield race([call(pollQuotes), take(floodDetailsAction.STOP_FLOOD_POLLING)]);
  }
}

function* stopPollingWorker() {
  const {
    floodDetails: { quotingDetails, quoteList, quoteErrorList, isPollingStart },
  } = yield select();
  if (isPollingStart && quotingDetails && quoteList.length + quoteErrorList.length > 0) {
    yield put(questionnaireSubmitLoader(false));
    yield put(setFloodDetailsLoader(false));
    yield put(
      setFloodDetails({
        isPollingStart: false,
      })
    );
  }
}

export function* stopPollingWatcher() {
  yield takeEvery(floodDetailsAction.STOP_FLOOD_POLLING, stopPollingWorker);
}

function* floodPollingTimeout() {
  const {
    floodDetails: { quoteList, quoteErrorList },
  } = yield select();
  if (quoteList.length + quoteErrorList.length === 0) {
    yield put(
      setFloodDetails({
        noQuoteAvailable: true,
      })
    );
  }
  yield put(stopFloodPolling());
}

function* floodPollingTimeoutWatcher() {
  yield takeLatest(floodDetailsAction.FLOOD_TIMEOUT_POLLING, floodPollingTimeout);
}

function* refreshFloodQuotesWorker() {
  const {
    floodDetails: { isQuoteRefresh },
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      setFloodDetails({
        isQuoteRefresh: true,
        isPollingStart: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initializeFloodPolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(
    setFloodDetails({
      isQuoteRefresh: false,
    } as any)
  );
  yield put(floodTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshfFloodQuotesWatcher() {
  yield takeEvery(floodDetailsAction.REFRESH_FLOOD_QUOTES, refreshFloodQuotesWorker);
}

export default function* rootSaga() {
  yield all([
    fork(questionnaireSubmitWatcher),
    fork(questionnaireBackWatcher),
    fork(fetchAnnexQuotesWatcher),
    fork(autoSubmitAnnexWatcher),
    fork(switchFloodModalWatcher),
    fork(trackAnnexDetailsWatcher),
    fork(fetchFloodQuotesDetailsWatcher),
    fork(getBridgeToCarrierDetailsForFloodWatcher),
    fork(pollQuotesWatcher),
    fork(stopPollingWatcher),
    fork(floodPollingTimeoutWatcher),
    fork(refreshfFloodQuotesWatcher),
  ]);
}
