/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { assignIn, get, isEmpty, isUndefined } from 'lodash';
import axiosRequest from '../../api';
import * as viewQuoteAction from '../constants';
import {
  CARRIER_ID,
  HOME_WARRANTY,
  INSURANCE_PRODUCTS,
  IN_PROGRESS_STATUS,
} from '../../../constants';
import history from '../../../history';
import {
  getObjectValues,
  clearReduxStateOnViewQuote,
  createPageTracking,
  getBoundedQuote,
  getHerdId,
  getSeperateQuoteList,
  inIframe,
  mappedResponseToStoreForPropertyDetails,
  setHeapProjectId,
  updateApplicantinfo,
  setViewQuoteDetails,
} from '../../../utils';
import {
  createUmbrellaQuestionsMapping,
  getMappedQuestionnaireResponses,
  getMappedUmbrellaQuestionnaireGroups,
  setUmbrellaQuotesMapping,
} from '../../../utils/umbrella';
import {
  clearQuoteLobDetails,
  errorHandler,
  getStoredDriversList,
  getStoredVehiclesList,
  setAllowEditQuotes,
  setAnnexPrefill,
  setApplicantDetails,
  setAutoQuoteBind,
  setFloodQuoteBind,
  setHomeCode,
  setHomeQuoteBind,
  setHomeState,
  setPconfig,
  setPolicyBounded,
  setPetPolicyBounded,
  setPageTracking,
  setQuakeQuoteBind,
  setQuoteLobDetails,
  setQuakeQuotes,
  setRequestId,
  setValidatingAddress,
  setValidateQuoteUrlLoader,
  setViewQuote,
  setFloodQuotes,
  setLobRefreshLoader,
  setHomeWarrantyQuoteDetails,
  setNoQuotesAvailableForHome,
  storeBoundedCarrierDetails,
  storeCommonData,
  storeUmbrellaQuoteDetails,
  setCrmSelectedLob,
  updateSelectedCarriers,
  viewQuoteLoader,
  setPrefillDetailsForRequestBind,
  setNextTabStep,
  setFloodDetails,
  setSelectedBusinessOption,
  flushAppDetails,
} from '../actions';
import config from '../../../config/config';
import {
  resetPropertyInfoDetails,
  setPropertyId,
  setPropertyOtherDetails,
  setPropertyRevision,
  setShowPropertyDataLooseWarning,
} from '../../../quote-application/redux/slices/property-info-details.slice';
import { resetHomeQuoteDetails, setHomeQuoteDetails } from '../../../quote-application/redux/slices';
import {
  residenceTypeMapping,
  updateCoverageDetailsForFlood,
  updateFloodQuestionnaireDetails,
} from '../../../quote-application/utils';
import { ResidenceType } from '../../../graphql/generated';

function* fetchViewQuotesDetails(details: any): any {
  yield put(resetPropertyInfoDetails());
  yield put(storeCommonData({ customId: '', loanNumber: '' }));
  yield put(setNoQuotesAvailableForHome(false));
  yield put(setLobRefreshLoader(false));
  yield put(setNextTabStep(false));
  yield put(resetHomeQuoteDetails());
  try {
    yield put(viewQuoteLoader(true));
    clearReduxStateOnViewQuote();
    const {
      home,
      partner: { isProviderOneLogin },
      viewQuoteDetails: { crmSelectedLob }
    } = yield select();
    const { defaultPConfig } = home
    let herdId = details.payload.herdId || getHerdId();
    yield put(
      setApplicantDetails({
        prefillDetails: null,
        userPropertyInformation: null,
        isApplicantSubmittedOnceForAuto: false,
        isMovedInPastMonths: false,
        previousAddress: null,
      })
    );
    sessionStorage.selectedQuoteList = [];
    const response = yield axiosRequest('POST', '/api/v1/crm/view', isProviderOneLogin, {
      crmSelectedLob,
      quoteId: herdId,
    });
    if (response.data) {
      const {
        annexRequiredPrefill,
        carriersBounded,
        organization,
        providerRequestId,
        quoteBounded,
        quotesByLOB,
        selectedCarriers,
        isValidationErrorExists,
        totalEzlynxQuotes,
        ezlynxQuoteExecutionID,
        lineOfBusiness,
        isPollingStart,
        customId,
        selectedLOBs,
        loanNumber,
        applicant,
        address,
        isMovedInPastMonths,
        previousAddress,
        pageTracking,
        adhocBoundedLobs,
        quotingCarriers,
        prefillData,
        prefillOverrideEnabled,
        noOfRetries,
        prefillStatus,
        propertyId,
        propertyRevision,
        showPropertyDataLooseWarning,
      } = response.data;
      if(!!customId || !!loanNumber || !!adhocBoundedLobs) {
        let partnerData: any = {
          customId: !!customId ? customId : '',
          loanNumber: !!loanNumber ? loanNumber : '',
          adhocBoundedLobs: !isEmpty(adhocBoundedLobs) ? adhocBoundedLobs : [],
        }
        yield put(storeCommonData({ ...partnerData, selectedLOBs }));
      }
      yield put(setPropertyId(!!propertyId ? propertyId : ''));
      yield put(setPropertyRevision(propertyRevision ?? 0));
      yield put(setShowPropertyDataLooseWarning(!!showPropertyDataLooseWarning));
      crmSelectedLob && (yield put(setSelectedBusinessOption(crmSelectedLob.toUpperCase())));
      setHeapProjectId(organization, true);
      if (!!selectedCarriers) {
        yield put(updateSelectedCarriers(selectedCarriers));
      }
      setViewQuoteDetails(quotesByLOB);
      updateApplicantinfo(applicant, address, previousAddress);
      let pageTrackings = createPageTracking(pageTracking, selectedLOBs);
      yield put(setHomeQuoteDetails({ isViewingQuote: true }));
      yield put(setPageTracking(pageTrackings));
      let pollingDetails: any = {
        isPollingStart: isPollingStart,
        totalEzlynxQuotes: totalEzlynxQuotes,
        lineOfBusiness: lineOfBusiness,
        ezlynxQuoteExecutionID: ezlynxQuoteExecutionID
      };
      yield put(
        setHomeState({
          defaultPConfig,
          pconfig: assignIn(
            {},
            {
              dale_config: organization,
            }
          ),
          ezlynxQuoteExecutionID: ezlynxQuoteExecutionID,
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          ...organization,
        } as any)
      );

      const applicantDetailsPayload: any = {
        prefillDetails: !isEmpty(prefillData)
          ? mappedResponseToStoreForPropertyDetails(prefillData)
          : {
            propertyData: {},
            label: {},
          },
        prefillStatus: prefillStatus || null,
        noOfRetries: noOfRetries || 0,
      };
      if (!isUndefined(isMovedInPastMonths)) {
        applicantDetailsPayload.isApplicantSubmittedOnceForAuto = true;
        applicantDetailsPayload.isMovedInPastMonths = isMovedInPastMonths;
        applicantDetailsPayload.previousAddress = !isEmpty(previousAddress)
          ? { ...previousAddress }
          : null;
      }
      yield put(setApplicantDetails(applicantDetailsPayload));
      if (!isEmpty(prefillData)) {
        const prefillDetails: any = mappedResponseToStoreForPropertyDetails(prefillData);
        if (!isEmpty(prefillDetails)) yield put(setPrefillDetailsForRequestBind(prefillDetails));
      }
      yield put(setLobRefreshLoader(false));
      const allowEditSetting = !!organization?.iframe_editing_enabled ? organization?.iframe_editing_enabled : false;
      yield put(setAllowEditQuotes(allowEditSetting));
      let isQuoteAvailable: boolean = false;
      if (quotesByLOB?.home) {
        const { quotes, error_details } = quotesByLOB?.home;
        isQuoteAvailable = !quotes && !error_details?.errors ? false : true;
      }
      const isPrefillEnabled =
        !!(!!config?.enablePrefillOverride &&
          !isQuoteAvailable &&
          selectedLOBs?.length === 1 &&
          !isEmpty(quotesByLOB?.home) &&
          !!prefillOverrideEnabled) ||
        false;
      pollingDetails = !isPrefillEnabled ? pollingDetails : {};
      yield put(setViewQuote({ isRedirectToApplicant: isValidationErrorExists || isPrefillEnabled, pollingDetails: pollingDetails }));
      yield put(setQuoteLobDetails({ ...response.data }));
      yield put(setRequestId(providerRequestId));
      yield put(setPolicyBounded(!!quoteBounded ? quoteBounded : {}));
      if (!!annexRequiredPrefill && annexRequiredPrefill?.lob === INSURANCE_PRODUCTS[2]) {
        const annexDetails = annexRequiredPrefill;
        yield put(
          setHomeState({
            totalEzlynxQuotes: 1,
          } as any)
        );
        yield put(setAnnexPrefill(annexDetails));
        updateCoverageDetailsForFlood(annexDetails);
        if (!!annexRequiredPrefill?.floodQuestionnaireAdditionalData?.additionalStructureType) {
          updateFloodQuestionnaireDetails(
            annexRequiredPrefill?.floodQuestionnaireAdditionalData?.additionalStructureType
          );
        }
      }
      yield put(setHomeCode(organization?.code));
      yield put(
        setPconfig({
          defaultPConfig,
          pconfig: {
            dale_config: { ...organization },
            ...organization
          },
        } as any)
      );
      if (annexRequiredPrefill?.residenceType) {
        const residenceType =
          residenceTypeMapping[
            annexRequiredPrefill?.residenceType?.toUpperCase() || ResidenceType.Primary?.toUpperCase()
          ];
        yield put(
          setPropertyOtherDetails({
            residenceType,
          })
        );
      }
      if (!!quotesByLOB?.home) {
        let homeData: any = {};
        const { quotes, error_details, status } = quotesByLOB.home;
        const boundedHomeQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedHomeQuote)) {
          yield put(setHomeQuoteBind(get(boundedHomeQuote[0], CARRIER_ID, '')));
        }
        if (
          isEmpty(quotes) &&
          isEmpty(error_details?.errors) &&
          !!error_details?.display_errors_on_ui &&
          status !== IN_PROGRESS_STATUS
        ) {
          yield put(setNoQuotesAvailableForHome(true));
        }
        homeData.herd_id = quotesByLOB?.home?.herdId;
        homeData.quoteList = !isEmpty(quotes) ? [...quotes] : [];
        const errors = error_details && error_details.errors
          ? Object.values(error_details.errors)
          : [];
        if (!isPollingStart) {
          homeData.quoteListError = errors;
          homeData.hippoQuoteLoaded = true;
          homeData.ezlynxQuotesLoaded = true;
        } else {
          homeData.tempErrorQuoteList = errors;
        }
        yield put(setHomeState(homeData));
      }
      if (!!quotesByLOB?.auto) {
        const { quotes } = quotesByLOB.auto;
        const boundedAutoQuote = getBoundedQuote(quotes);

        if (quotesByLOB.auto.vehicle_details && quotesByLOB.auto.vehicle_details.length) {
          yield put(getStoredVehiclesList(quotesByLOB.auto.vehicle_details));
        }

        if (quotesByLOB.auto.driver_details && quotesByLOB.auto.driver_details.length) {
          yield put(getStoredDriversList(quotesByLOB.auto.driver_details));
        }

        if (!isEmpty(boundedAutoQuote)) {
          yield put(setAutoQuoteBind(get(boundedAutoQuote[0], CARRIER_ID, '')));
        }
      }
      if (!!quotesByLOB?.flood) {
        const { quotes, error_details, selectedCarriers } = quotesByLOB.flood;
        const boundedFloodQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedFloodQuote)) {
          yield put(setFloodQuoteBind(get(boundedFloodQuote[0], CARRIER_ID, '')));
        }
        const { errorQuotes } = getSeperateQuoteList(error_details, {});
        yield put(setFloodQuotes({
          quoteList: quotes ? quotes : [],
          quoteErrorList: errorQuotes,
          selectedCarrier: selectedCarriers?.flood?.carrierId ? selectedCarriers?.flood : {},
          herdId: quotesByLOB?.flood?.herdId,
        }));
        yield put(
          setFloodDetails({
            isPropertyInfoRequiredForFlood: false,
          })
        );
      }
      if (!!quotesByLOB?.quake) {
        const { quotes, error_details } = quotesByLOB.quake;
        const boundedQuakeQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedQuakeQuote)) {
          yield put(setQuakeQuoteBind(get(boundedQuakeQuote[0], CARRIER_ID, '')));
        }
        const { errorQuotes } = getSeperateQuoteList(error_details, {});
        setQuakeQuotes({
          herdId: quotesByLOB?.quake?.herdId,
          quoteList: [...getObjectValues(quotes), ...getObjectValues(errorQuotes)],
        });
      }
      if (!!quotesByLOB[HOME_WARRANTY]) {
        const { quotes, error_details, herdId: herd_id } = quotesByLOB[HOME_WARRANTY];
        const { errorQuotes } = getSeperateQuoteList(error_details,{});
        setHomeWarrantyQuoteDetails({
          quoteList: [...getObjectValues(quotes), ...getObjectValues(errorQuotes)],
          herdId: herd_id,
        });
      }
      if (!!quotesByLOB?.pet && quotesByLOB?.pet?.isPolicyBounded) {
        yield put(setPetPolicyBounded(true));
      }
      if (!!quotesByLOB?.umbrella) {
        const { quotes, error_details, herdId: herd_id, rliQuestionInfo } = quotesByLOB.umbrella;
        const { errorQuotes } = getSeperateQuoteList(error_details, {});
        let umbrellaQuestions: any =
          rliQuestionInfo && !isEmpty(rliQuestionInfo)
            ? getMappedUmbrellaQuestionnaireGroups(
                createUmbrellaQuestionsMapping([...rliQuestionInfo])
              )
            : null;
        yield put(
          storeUmbrellaQuoteDetails({
            umbrellaQuestionsResponse: !!umbrellaQuestions
              ? getMappedQuestionnaireResponses(umbrellaQuestions)
              : null,
            quoteList: setUmbrellaQuotesMapping([...getObjectValues(quotes)]),
            quoteErrorList: [...getObjectValues(errorQuotes)],
            herdId: herd_id,
            umbrellaInfo: {
              questionGroups: umbrellaQuestions,
            },
          })
        );
      }
      if (!isEmpty(carriersBounded)) {
        if (!!carriersBounded?.earthquake) {
          carriersBounded.quake = carriersBounded?.earthquake;
          delete carriersBounded.earthquake;
        }
        yield put(storeBoundedCarrierDetails(carriersBounded));
      }
      if (
        !quotesByLOB?.[INSURANCE_PRODUCTS[0]] &&
        !quotesByLOB?.[INSURANCE_PRODUCTS[2]] &&
        !quotesByLOB?.[INSURANCE_PRODUCTS[3]] &&
        selectedLOBs?.includes(INSURANCE_PRODUCTS[0]?.toUpperCase()) &&
        isEmpty(home?.quoteList) &&
        isEmpty(home?.quoteListError)
      ) {
        yield put(
          setHomeState({
            productType: '',
          } as any)
        );
        yield put(setValidatingAddress(false));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!inIframe() && axiosError?.response?.status === 404) {
      yield put(
        setHomeState({
          showError: true,
          notFoundError: false,
        } as any)
      );
      history.push('/error');
    }
    yield put(errorHandler(axiosError));
  }
  yield put(viewQuoteLoader(false));
}

export function* fetchViewQuotesDetailsWatcher() {
  yield takeEvery(viewQuoteAction.FETCH_VIEW_QUOTES, fetchViewQuotesDetails);
}

function* validateQuoteUrl(details: any): any {
  try {
    yield put(setValidateQuoteUrlLoader(true));
    yield put(flushAppDetails());
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const response = yield axiosRequest('POST', '/api/v1/crm/validate-url', isProviderOneLogin, {
      quoteToken: details?.payload?.token,
    });
    if (response.data) {
      const { isExpired } = response.data;
      yield put(clearQuoteLobDetails());
      if (!isExpired && response?.data?.quoteId) {
        yield put(setCrmSelectedLob(response?.data?.crmSelectedLob || ''));
        const quoteUrl = `/quote?id=${response?.data?.quoteId}`;
        history.push(quoteUrl);
      } else {
        yield put(
          setHomeState({
            showError: true,
            notFoundError: false,
            expiredError: true,
          } as any)
        );
        history.push('/error');
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!inIframe() && axiosError?.response?.status === 404) {
      yield put(
        setHomeState({
          showError: true,
          notFoundError: true,
          expiredError: false,
        } as any)
      );
      history.push('/error');
    }
    yield put(errorHandler(axiosError));
  }
  yield put(setValidateQuoteUrlLoader(false));
}

export function* validateQuoteUrlWatcher() {
  yield takeEvery(viewQuoteAction.VALIDATE_QUOTE_URL, validateQuoteUrl);
}

export default function* rootSaga() {
  yield all([
    fork(fetchViewQuotesDetailsWatcher),
    fork(validateQuoteUrlWatcher),
  ]);
}
