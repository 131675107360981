import React, { ChangeEvent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  NativeSelect,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  questionnaireBack,
  questionnaireSubmit,
  setFloodQuotes,
} from '../../../pages/redux/actions';
import './flood-questionnaire.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import config from '../../../config/config';
import {
  allowEditingQuote,
  getIsHeapAnalyticsEnabled,
  getIsPropertyInfoEnabledDetails,
  getLobHerdId,
  positiveIntegerRegex,
  removeExistingRate,
} from '../../../utils';
import {
  ASTERISK,
  BACK,
  CONTINUE,
  INSURANCE_BUSINESS_TYPES,
  PROPERTY_INFO,
  QUOTING_STEPS_PAGES_NAME,
} from '../../../constants';
import NumberStepper from '../../../pages/components/number-stepper';
import ThemesImages from '../../../pages/components/themes-images';
import NumberFormatCustom from '../../../pages/components/currency-mask';
import {
  FloodQuestionnaireDispatch,
  FloodQuestionnaireProps,
  FloodQuestionnaireState,
  FloodQuestionnaireStore,
} from './types';
import { LineOfBusiness } from '../../enums';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: 'inherit',
    padding: 0,
  },
});

class FloodQuestionnaire extends React.PureComponent<
  FloodQuestionnaireProps & WithStyles<typeof styles>,
  FloodQuestionnaireState
> {
  state: FloodQuestionnaireState = {
    currentQuestion: null,
    mainError: '',
    linkError: '',
    shouldChangeTab: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setInitialQuestion(this.props);
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.FLOOD_INFO);
    }
    const { currentIndex, goToLastTab, floodDetails } = this.props;
    if (!floodDetails?.isFloodInfoRequired) {
      this.handleSubmit();
      return goToLastTab(currentIndex);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: FloodQuestionnaireProps) {
    const { currentIndex, goToLastTab } = this.props;
    if (newProps.floodDetails.isNextTabStep && this.state.shouldChangeTab) {
      goToLastTab(currentIndex);
    }

    if (!newProps.floodDetails.detailsLoader && this.props.floodDetails.detailsLoader) {
      this.setInitialQuestion(newProps);
    }

    if (newProps?.floodDetails?.currentQuestion?.name !== this.state.currentQuestion?.name) {
      this.setState({
        currentQuestion: { ...newProps.floodDetails.currentQuestion },
      });
    }
  }
  getCurrentQuestionRenderer = (
    currentQuestion: any,
    type: string,
    fieldError: string,
    isDisabled: boolean,
    disableFloodEdit: boolean
  ) => {
    switch (currentQuestion?.type) {
      case 'dropdown':
        return (
          <FormControl
            className={`form-control ${type === 'alt' && 'alt-form-control'}`}
            error={fieldError?.length > 0}
            disabled={isDisabled || disableFloodEdit}
          >
            <InputLabel shrink htmlFor={currentQuestion.name}>
              {currentQuestion.title}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <NativeSelect
              className={currentQuestion.name}
              value={currentQuestion.value}
              inputProps={{
                name: currentQuestion.name,
                id: currentQuestion.name,
              }}
              disabled={isDisabled || disableFloodEdit}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => this.fieldChange(event, type)}
            >
              <option value=''>Select</option>
              {currentQuestion.options.map((option: any, key: number) => (
                <option key={`${option.value}-${key}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect>
            {fieldError && <div className='error ml-0'>{fieldError}</div>}
          </FormControl>
        );

      case 'numberInput':
        return (
          <FormControl
            className={`form-control ${type === 'alt' && 'alt-form-control'}`}
            error={fieldError.length > 0}
            disabled={isDisabled || disableFloodEdit}
          >
            <InputLabel shrink htmlFor={currentQuestion.name}>
              {currentQuestion.title}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              autoComplete='off'
              value={currentQuestion.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const checkValidInput = String(event.target.value).match(positiveIntegerRegex);
                const inputValue = checkValidInput ? event.target.value : '';
                this.fieldChange({ target: { value: inputValue } }, type);
              }}
              inputProps={{ maxLength: 10, name: 'approx_miles' }}
              inputComponent={NumberFormatCustom as any}
              name={currentQuestion.name}
              disabled={isDisabled || disableFloodEdit}
              id={currentQuestion.name}
            />
            {fieldError && <div className='error ml-0'>{fieldError}</div>}
          </FormControl>
        );

      case 'numberStepper':
        return (
          <FormControl
            className={`form-control number-stepper-control ${
              type === 'alt' && 'alt-form-control'
            }`}
            error={fieldError.length > 0}
            disabled={isDisabled || disableFloodEdit}
          >
            <InputLabel shrink htmlFor={currentQuestion.name}>
              {currentQuestion.title} <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <NumberStepper
              styleName='number-stepper'
              value={currentQuestion.value}
              disabled={isDisabled || disableFloodEdit}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => this.fieldChange(event, type)}
            />
            {fieldError && <div className='error'>{fieldError}</div>}
          </FormControl>
        );
    }
  };

  checkLinkedQuestion = (question: any) => {
    if (!!question && question?.linkValues && question?.value) {
      return question?.linkValues.includes(question?.value);
    }
    return false;
  };

  setInitialQuestion = (initialProps: FloodQuestionnaireProps) => {
    const { currentQuestion } = initialProps.floodDetails;
    this.setState({
      currentQuestion,
    });
  };

  fieldChange = ({ target }: any, type: string) => {
    if (type === 'main') {
      const updateCurrentQuestion = {
        currentQuestion: {
          ...this.state.currentQuestion,
          value: target.value,
        },
      };
      if (this.checkLinkedQuestion(this.state.currentQuestion)) {
        updateCurrentQuestion.currentQuestion.linkQuestion = {
          ...this.state.currentQuestion.linkQuestion,
          value: '',
        };
        this.setState(updateCurrentQuestion);
      } else {
        this.setState(updateCurrentQuestion);
      }
    } else {
      this.setState({
        currentQuestion: {
          ...this.state.currentQuestion,
          linkQuestion: {
            ...this.state.currentQuestion.linkQuestion,
            value: target.value,
          },
        },
      });
    }
  };

  handleSubmit = () => {
    // todo:fix this
    const { currentQuestion } = this.state;
    const isCurrentQuestionValid = isEmpty(String(currentQuestion?.value));
    const linkedQuestionValue = currentQuestion?.linkQuestion?.value
      ? isEmpty(currentQuestion?.linkQuestion?.value?.toString())
      : true;
    const isLinkedQuestionValid = this.checkLinkedQuestion(currentQuestion) && linkedQuestionValue;
    if (isCurrentQuestionValid || isLinkedQuestionValid) {
      this.setState({
        mainError: isCurrentQuestionValid ? 'Required' : '',
        linkError: isLinkedQuestionValid ? 'Required' : '',
      });
    } else {
      const { currentIndex, floodDetails, goToLastTab } = this.props;
      const disableFlood = allowEditingQuote();
      if (
        !disableFlood?.flood &&
        (currentQuestion?.value?.toString() !== floodDetails?.currentQuestion?.value?.toString() ||
          isEmpty(floodDetails?.quoteList) ||
          (!!currentQuestion?.linkQuestion &&
            !!floodDetails?.currentQuestion?.linkQuestion &&
            floodDetails?.currentQuestion?.linkQuestion?.value?.toString() !==
              currentQuestion?.linkQuestion?.value?.toString()))
      ) {
        this.setState({
          mainError: '',
          linkError: '',
          shouldChangeTab: true,
        });
        this.props.setFloodQuotes({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] });
        if (!isEmpty(floodDetails?.annexPrefill) && !isEmpty(getLobHerdId(LineOfBusiness.FLOOD))) {
          this.props.questionnaireSubmit(currentQuestion);
        } else {
          goToLastTab(currentIndex);
        }
        if (sessionStorage.selectedQuoteList) {
          const selectedQuotes = removeExistingRate(
            JSON.parse(sessionStorage.selectedQuoteList),
            INSURANCE_BUSINESS_TYPES.FLOOD
          );
          sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
        }
      } else {
        goToLastTab(currentIndex);
      }
    }
  };

  handleBack = () => {
    const { currentIndex } = this.props.floodDetails;
    const { onPrevTab, currentIndex: currentTabIndex, redirectToStep } = this.props;
    this.setState({
      mainError: '',
      linkError: '',
    });
    if (getIsPropertyInfoEnabledDetails()) {
      return redirectToStep(PROPERTY_INFO.KEY);
    }
    // this is the first question
    if (currentIndex === 0) {
      return onPrevTab(currentTabIndex);
    }
    this.props.questionnaireBack();
  };

  render() {
    const { currentQuestion, mainError, linkError } = this.state;
    const {
      floodDetails: { submitLoader, isFloodInfoRequired },
    } = this.props;

    const themeType = !!this.props.home.pconfig?.dale_config?.branding_on_dale_details
      ?.template_name
      ? this.props.home.pconfig?.dale_config?.branding_on_dale_details?.template_name
      : config.hippo.template_name;
    const disableFlood = allowEditingQuote();
    return (
      <div className='quote-questionnaire-container pt-0'>
        <>
          {!!isFloodInfoRequired ? (
            <div className='row justify-content-center'>
              <div className='col-lg-12'>
                {currentQuestion && (
                  <>
                    {this.getCurrentQuestionRenderer(
                      currentQuestion,
                      'main',
                      mainError,
                      submitLoader,
                      disableFlood?.flood
                    )}
                  </>
                )}
                {this.checkLinkedQuestion(currentQuestion) &&
                  this.getCurrentQuestionRenderer(
                    currentQuestion.linkQuestion,
                    'alt',
                    linkError,
                    submitLoader,
                    disableFlood?.flood
                  )}
              </div>
              <div className='row form-submit-action d-flex justify-content-center'>
                <div className='col-lg-12'>
                  {this.props.common.isAddingProducts && this.props.currentIndex === 1 ? null : (
                    <button className='btn m-2 btn-back mh-button' onClick={this.handleBack}>
                      <ArrowBackIosIcon className='mr-2' />
                      {BACK}
                    </button>
                  )}
                  <Button
                    variant='contained'
                    className='btnContinue'
                    disabled={submitLoader}
                    onClick={() => this.handleSubmit()}
                  >
                    {CONTINUE}
                    {submitLoader && <CircularProgress size={20} className='submit-loader ml-2' />}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className='w-100 mt-4 d-flex justify-content-center'>
              <img src={ThemesImages[themeType].floodLoader} alt='loader' />
            </div>
          )}
        </>
      </div>
    );
  }
}

const mapStateToProps = ({
  home,
  floodDetails,
  common,
}: FloodQuestionnaireStore): FloodQuestionnaireStore => {
  return {
    home,
    floodDetails,
    common,
  };
};

const mapDispatchToProps = (dispatchProp: any): FloodQuestionnaireDispatch => {
  return bindActionCreators(
    { questionnaireBack, questionnaireSubmit, setFloodQuotes },
    dispatchProp
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(FloodQuestionnaire));
